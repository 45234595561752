import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import PreFooter from '../components/PreFooter';
import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { theme } from '../styles/theme';
import { Link } from 'gatsby';
import { SubSiteWrapper } from '../styles/styleTemplates';

export default ({}) => {
    return (
        <Layout backgroundColor={theme.colors.veryLightPink}>
            <Navigation hideBg={true} />
            <SubSiteWrapper>
                <Row>
                    <Col>
                        <h2>Datenschutz</h2>
                        <h2>Einleitung</h2>
                        <p>
                            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
                            auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
                            von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch
                            insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer
                            Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot“).
                            <br />
                            Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
                            <br />
                            Stand: 17. Dezember 2019
                        </p>
                        <br />
                        <h2>Inhaltsübersicht</h2>
                        <p>
                            - Einleitung <br />
                            - Verantwortlicher
                            <br />
                            - Übersicht der Verarbeitungen
                            <br />
                            - Kontakt Datenschutzbeauftragter
                            <br />
                            - Maßgebliche Rechtsgrundlagen
                            <br />
                            - Sicherheitsmaßnahmen
                            <br />
                            - Übermittlung und Offenbarung von personenbezogenen Daten
                            <br />
                            - Datenverarbeitung in Drittländern
                            <br />
                            - Einsatz von Cookies
                            <br />
                            - Kontaktaufnahme
                            <br />
                            - Bereitstellung des Onlineangebotes und Webhosting
                            <br />
                            - Bewerbungsverfahren
                            <br />
                            - Newsletter und Breitenkommunikation
                            <br />
                            - Gewinnspiele und Wettbewerbe
                            <br />
                            - Onlinemarketing
                            <br />
                            - Präsenzen in sozialen Netzwerken
                            <br />
                            - Plugins und eingebettete Funktionen sowie Inhalte
                            <br />
                            - Löschung von Daten
                            <br />
                            - Änderung und Aktualisierung der Datenschutzerklärung
                            <br />
                            - Rechte der betroffenen Personen
                            <br />
                            - Begriffsdefinitionen
                            <br />
                        </p>
                        <br />
                        <h2>Verantwortlicher</h2>
                        <p>
                            PROJECT FLOORS GmbH <br />
                            Max-Ernst-Straße 4<br />
                            50354 Hürth
                            <br />
                            Vertretungsberechtigte Personen
                            <br />
                            sind Geschäftsführer:
                            <br />
                            Bernd Greve, Zoran Stopar
                            <br />E <a href="mailto:info@project-floors.com">info( at )project-floors.com</a>
                            <br />T <a href="tel:+490223396870">02233 9687 0</a>
                            <br />
                            Impressum: <br />
                            <Link to="/impressum">www.bodengutgemacht.de/impressum/</Link>
                        </p>
                        <br />
                        <h2>Datenschutz-beauftragter</h2>
                        <p>
                            PROJECT FLOORS GmbHvr <br />
                            Max-Ernst-Str. 4<br />
                            50354 Hürth
                            <br />
                            datenschutz( at )project-floors.com
                            <br /> <br />
                            Finale Inhalte fehlen. Diese Seite dient nur zur Ansicht.
                        </p>
                    </Col>
                </Row>
            </SubSiteWrapper>
            <PreFooter />
            <Footer />
        </Layout>
    );
};
